import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Image,
  Stack,
  // Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import serum from "../assets/serum.JPG";
import carnitine from "../assets/IMG_0319.JPG";
import prePump from "../assets/IMG_0320.JPG";
import muscleGain from "../assets/IMG_0321.JPG";
import liverSupport from "../assets/IMG_0322.JPG";
import cutfire from "../assets/IMG_0323.JPG";
import Healthy_Hair_combo from "../assets/IMG_0324.JPG";
import hairGrowth from "../assets/IMG_0325.JPG";
import advanceBiotin from "../assets/IMG_0327.JPG";

const MotionBox = motion(Box);

const products = [
  {
    id: 1,
    name: "Serum",
    imageUrl: serum,
    description:
      "A nourishing serum designed to promote hair growth and improve hair texture.",
    benefits: [
      "Promotes hair growth",
      "Improves hair texture",
      "Strengthens hair roots",
    ],
  },
  {
    id: 2,
    name: "Carnitine Supplement",
    imageUrl: carnitine,
    description:
      "A powerful fat burner and energy booster supplement for effective weight management.",
    benefits: ["Supports fat loss", "Boosts energy", "Improves endurance"],
  },
  {
    id: 3,
    name: "Pre-Pump",
    imageUrl: prePump,
    description:
      "Pre-workout formula that enhances performance, focus, and stamina during intense workouts.",
    benefits: ["Enhances performance", "Boosts stamina", "Improves focus"],
  },
  {
    id: 4,
    name: "Muscle Gain Supplement",
    imageUrl: muscleGain,
    description:
      "A high-protein formula designed to aid muscle recovery and growth post-workout.",
    benefits: [
      "Promotes muscle growth",
      "Enhances recovery",
      "Increases strength",
    ],
  },
  {
    id: 5,
    name: "Liver Support",
    imageUrl: liverSupport,
    description:
      "A supplement that supports liver function and detoxification for overall health.",
    benefits: [
      "Supports liver health",
      "Aids in detoxification",
      "Improves digestion",
    ],
  },
  {
    id: 6,
    name: "Cutfire",
    imageUrl: cutfire,
    description:
      "A thermogenic fat burner designed to help shed excess body fat and improve metabolism.",
    benefits: ["Burns fat", "Boosts metabolism", "Increases energy"],
  },
  {
    id: 7,
    name: "Healthy Hair Combo",
    imageUrl: Healthy_Hair_combo,
    description:
      "A comprehensive hair care combo that promotes healthier, stronger, and shinier hair.",
    benefits: [
      "Strengthens hair",
      "Reduces hair fall",
      "Promotes shine and volume",
    ],
  },
  {
    id: 8,
    name: "Hair Growth Supplement",
    imageUrl: hairGrowth,
    description:
      "A targeted supplement to stimulate hair growth and prevent hair thinning.",
    benefits: [
      "Stimulates hair growth",
      "Prevents hair thinning",
      "Improves scalp health",
    ],
  },
  {
    id: 9,
    name: "Advanced Biotin",
    imageUrl: advanceBiotin,
    description:
      "A biotin-rich formula to support hair, skin, and nail health for a radiant appearance.",
    benefits: [
      "Promotes hair health",
      "Supports skin health",
      "Strengthens nails",
    ],
  },
];

const Products = () => {
  return (
    <MotionBox
      id="products"
      py={24}
      px={4}
      bg="gray.900"
      color="white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Heading as="h2" size="2xl" mb={12} textAlign="center" color="yellow.400">
        Our Products
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={10}
        maxW="1200px"
        mx="auto"
      >
        {products.map((product) => (
          <Box
            key={product.id}
            bg="gray.800"
            borderRadius="md"
            overflow="hidden"
            boxShadow="md"
            transition="transform 0.3s"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Image
              src={product.imageUrl}
              alt={product.name}
              w="full"
              h="400px"
              objectFit="cover"
            />
            <Box p={6}>
              <Heading as="h3" size="lg" mb={4} color="yellow.300">
                {product.name}
              </Heading>
              <Text fontSize="md" mb={4}>
                {product.description}
              </Text>
              <Stack spacing={2} mb={6}>
                {product.benefits.map((benefit, index) => (
                  <Text key={index} fontSize="sm" color="yellow.200">
                    - {benefit}
                  </Text>
                ))}
              </Stack>
              {/* <Button colorScheme="yellow" variant="solid" w="full">
                Learn More
              </Button> */}
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </MotionBox>
  );
};

export default Products;
