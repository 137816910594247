// src/components/AboutUs.js
import { Box, Heading, Text, SimpleGrid, Stack, Divider } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const AboutUs = () => {
  return (
    <MotionBox
      id="about-us"
      py={24}
      px={4}
      bg="black"
      color="white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Heading as="h2" size="2xl" mb={12} textAlign="center" color="yellow.400">
        About Us
      </Heading>
      
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} maxW="1200px" mx="auto">
        <Box>
          <Heading as="h3" size="lg" mb={4} color="yellow.300">
            Our Mission
          </Heading>
          <Text fontSize="md" mb={6}>
            Our mission is to empower individuals to live healthier, more active lives through the use of premium supplements. We believe in the power of nutrition to transform lives and are committed to supporting our customers every step of the way.
          </Text>
          <Divider borderColor="yellow.300" mb={6} />
          <Heading as="h3" size="lg" mb={4} color="yellow.300">
            Our Values
          </Heading>
          <Text fontSize="md">
            Integrity, quality, and customer satisfaction are the core values that drive our company. We strive to maintain transparency in our processes, ensure the highest quality in our products, and provide exceptional service to our customers.
          </Text>
        </Box>
        <Box>
          <Heading as="h3" size="lg" mb={4} color="yellow.300">
            Our Story
          </Heading>
          <Text fontSize="md">
            Founded with a vision to make a positive impact on the world of health and wellness, our company started from humble beginnings. Over the years, we have grown into a leading provider of supplements, constantly innovating to meet the needs of our diverse clientele.
          </Text>
          <Divider borderColor="yellow.300" mt={6} />
        </Box>
      </SimpleGrid>
    </MotionBox>
  );
};

export default AboutUs;
