// src/components/Navbar.js
import {
  Box,
  Flex,
  Image,
  Heading,
  Link,
  // Spacer,
  // Text,
  IconButton,
  Collapse,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from "../assets/logo_nobg.png";

const MotionLink = motion(Link);

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      as="header"
      bg="black"
      color="yellow.400"
      // py={2}
      borderBottom="2px solid yellow.400"
    >
      <Flex
        align="center"
        maxW="1200px"
        mx="auto"
        px={4}
        wrap="wrap"
        justify="space-between"
      >
        {/* Logo and Brand Name */}
        <Flex align="center" mb={{ base: 4, md: 0 }}>
          <Image src={logo} alt="Logo" boxSize="90px" objectFit="contain" />
          <Heading
            as="h1"
            size="lg"
            ml={2}
            color="yellow.400"
            fontFamily="'Roboto', sans-serif"
            letterSpacing="wider"
          >
            Muscle Core
          </Heading>
        </Flex>

        {/* Hamburger Menu Button */}
        <IconButton
          aria-label="Toggle Navigation"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          onClick={onToggle}
          display={{ base: "block", md: "none" }}
          color="yellow.400"
          variant="outline"
        />

        {/* Navigation Links and Mobile Number */}
        <Collapse in={isOpen} animateOpacity>
          <VStack
            spacing={4}
            align="center"
            display={{ base: "flex", md: "none" }}
            mb={4}
          >
            <MotionLink
              href="#home"
              px={4}
              color="yellow.400"
              _hover={{ textDecoration: "underline" }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              Home
            </MotionLink>
            <MotionLink
              href="#about-us"
              px={4}
              color="yellow.400"
              _hover={{ textDecoration: "underline" }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              About Us
            </MotionLink>
            <MotionLink
              href="#products"
              px={4}
              color="yellow.400"
              _hover={{ textDecoration: "underline" }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              Products
            </MotionLink>
            {/* <MotionLink
              href="#location"
              px={4}
              color="yellow.400"
              _hover={{ textDecoration: 'underline' }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              Location
            </MotionLink>
            <Text px={4} color="yellow.400" fontWeight="bold">
              +91 98133-48401
            </Text> */}
          </VStack>
        </Collapse>

        {/* Desktop Navigation Links */}
        <Flex align="center" display={{ base: "none", md: "flex" }}>
          <MotionLink
            href="#home"
            px={4}
            color="yellow.400"
            _hover={{ textDecoration: "underline" }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            Home
          </MotionLink>
          <MotionLink
            href="#about-us"
            px={4}
            color="yellow.400"
            _hover={{ textDecoration: "underline" }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            About Us
          </MotionLink>
          <MotionLink
            href="#products"
            px={4}
            color="yellow.400"
            _hover={{ textDecoration: "underline" }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            Products
          </MotionLink>
          {/* <MotionLink
            href="#location"
            px={4}
            color="yellow.400"
            _hover={{ textDecoration: 'underline' }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            Location
          </MotionLink>
          <Text px={4} color="yellow.400" fontWeight="bold">
            +91 98133-48401
          </Text> */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
