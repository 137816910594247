// src/components/Footer.js
import { Box, Container, Heading,
  //  Text, 
    Stack, Image,
    //  Link
     } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import logo from "../assets/logo_nobg.png"

// Define the motion component for animation
const MotionBox = motion(Box);

const Footer = () => {
  return (
    <MotionBox
      as="footer"
      py={8}
      bg="gray.800"
      color="white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container maxW="container.xl">
        <Stack spacing={1} align="center" textAlign="center">
          <Image
            src={logo}
            alt="The Muscle Core Logo"
            boxSize="100px"
            objectFit="contain"
          />
          <Heading as="h3" size="lg">
            The Muscle Core
          </Heading>
          {/* <Text fontSize="md">
            Ravi Dass Market, OPP. New Grain Market, G.T Road,<br />
            Samalkha, Panipat, Haryana, 132101
          </Text> */}
          {/* <Text fontSize="md">
            Contact No: +91 98133-48401
          </Text> */}
          {/* <Text fontSize="sm">
            GST No: 06AIXPC2342N1ZX
          </Text> */}
          {/* <Link href="mailto:pahalwansupplements@gmail.com" color="yellow.400" fontSize="md">
          pahalwansupplements@gmail.com
          </Link> */}
        </Stack>
      </Container>
    </MotionBox>
  );
};

export default Footer;
