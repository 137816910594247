import { Box, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
// import Healthy_Hair_combo from "../assets/IMG_0324.JPG";

const MotionBox = motion(Box);

const Home = () => {
  return (
    <MotionBox
      id="home"
      position="relative"
      h="100vh"
      // bgImage={Healthy_Hair_combo}
      bgImage='url("https://plus.unsplash.com/premium_photo-1670505062582-fdaa83c23c9e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Zml0bmVzcyUyMHN1cGxpZW1lbnRzfGVufDB8fDB8fHww")'
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
    >
      <Box
        textAlign="center"
        px={4}
        maxW="800px"
        mx="auto"
        bg="rgba(0, 0, 0, 0.6)" // Dark overlay for better text readability
        p={8}
        borderRadius="md"
      >
        <Heading as="h1" size="2xl" mb={4}>
          Elevate Your Health with Supplements
        </Heading>
        <Text fontSize="lg">
          Achieve your fitness goals with our high-quality supplements. From boosting energy and enhancing recovery to building muscle, our products are crafted to support every step of your health journey.
        </Text>
      </Box>
    </MotionBox>
  );
};

export default Home;
