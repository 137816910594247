// src/App.js
// import { ChakraProvider } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Products from "./components/Products";
// import Location from "./components/Location";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <AboutUs />
      <Products />
      {/* <Location /> */}
      <Footer />
    </>
  );
}

export default App;
